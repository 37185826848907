import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  Space,
  Popconfirm,
} from "antd";
import adminService from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import RuleDataForm from "../components/servicesForms/RuleDataForm";

const { Option } = Select;

export interface RuleDataLocation {
  supplierId: number;
  price: number;
  location: string;
}

export interface RuleDataCycle {
  cycle: number;
  price: number;
  supplierId: number;
  cycleCounter: number;
}

export interface RuleDataDirect {
  price: number;
  supplierId: number;
}

export type RuleData = RuleDataLocation | RuleDataCycle | RuleDataDirect;

export interface Buybox {
  id: string;
  ruleType: "location" | "cycle" | "direct";
  targetSupplierId: string;
  targetPrice: string;
  ruleData: RuleData[];
}

const BuyboxComponent = () => {
  const [buyboxes, setBuyboxes] = useState<Buybox[]>([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingBuybox, setEditingBuybox] = useState<Buybox | null>(null);
  const [form] = Form.useForm();
  const [supplierProductVariants, setSupplierProductVariants] = useState([]);
  const [
    selectedSupplierProductVariantId,
    setSelectedSupplierProductVariantId,
  ] = useState<number | null>(null);

  const getBuyboxes = async () => {
    setLoading(true);
    try {
      const res = await adminService.getBuyboxes();
      setBuyboxes(res.data);
    } catch (error) {
      alertNotification("error", "Bir hata oluştu!");
    } finally {
      setLoading(false);
    }
  };

  const getSupplierProductVariants = async (search: string) => {
    try {
      const response = await adminService.getSupplierProductVariants(search);
      setSupplierProductVariants(response.data.data);
    } catch (error) {
      alertNotification("error", "Ürünler yüklenirken bir hata oluştu!");
    }
  };

  useEffect(() => {
    getBuyboxes();
  }, []);

  const showModal = (buybox?: Buybox) => {
    setIsModalVisible(true);
    if (buybox) {
      setEditingBuybox(buybox);
      form.setFieldsValue({
        ...buybox,
        ruleData: buybox.ruleData,
      });
    } else {
      setEditingBuybox(null);
      form.resetFields();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingBuybox(null);
    form.resetFields();
  };

  const handleDelete = async (id: string) => {
    try {
      await adminService.deleteBuybox(Number(id));
      alertNotification("success", "Buybox başarıyla silindi!");
      getBuyboxes();
    } catch (error) {
      alertNotification("error", "Bir hata oluştu!");
    }
  };

  const onFinish = async (values: any) => {
    const data = {
      ...values,
      ruleData: values.ruleData,
      supplierProductVariantId: selectedSupplierProductVariantId, // Supplier product variant id ekleniyor
    };
    try {
      if (editingBuybox) {
        await adminService.updateBuybox(Number(editingBuybox.id), data);
        alertNotification("success", "Buybox başarıyla güncellendi!");
      } else {
        await adminService.createBuybox(data);
        alertNotification("success", "Buybox başarıyla oluşturuldu!");
      }
      handleCancel();
      getBuyboxes();
    } catch (error) {
      alertNotification("error", "Bir hata oluştu!");
    }
  };

  const columns = [
    {
      title: "Supplier Product Variant",
      dataIndex: "supplier_product_variant",
      key: "supplier_product_variant",
      render: (supplier_product_variant: any, record: Buybox) => (
        <div>
          {" "}
          <img
            src={"https://api.rexven.com/images/marketplace/supplier-images/"+supplier_product_variant.imageList[0]}
            alt={supplier_product_variant.name}
            style={{ width: 150, height: 150, objectFit: "contain" }}/>
          <div>
          <span>{supplier_product_variant.id} - </span>
          <span>{supplier_product_variant.name}</span>
          </div>
         
        </div>
      ),
    },
    {
      title: "Rule Type",
      dataIndex: "ruleType",
      key: "ruleType",
    },
    {
      title: "Rule Data",
      dataIndex: "ruleData",
      key: "ruleData",
      render: (ruleData: RuleData[], record: Buybox) => (
        <pre>
          {Object.keys(ruleData).map((key) => {
            const rule = ruleData[Number(key)];
            if (record.ruleType === "location") {
              const locationRule = rule as RuleDataLocation;
              return `Supplier ID: ${locationRule.supplierId}, Price: ${locationRule.price}, Location: ${locationRule.location}\n`;
            } else if (record.ruleType === "cycle") {
              const cycleRule = rule as RuleDataCycle;
              return `Supplier ID: ${cycleRule.supplierId}, Price: ${cycleRule.price}, Cycle: ${cycleRule.cycle}, Cycle Counter: ${cycleRule.cycleCounter}\n`;
            } else {
              return `Supplier ID: ${rule.supplierId}, Price: ${rule.price}\n`;
            }
          })}
        </pre>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: Buybox) => (
        <Space>
          <Button type="link" onClick={() => showModal(record)}>
            Düzenle
          </Button>
          <Popconfirm
            title="Silmek istediğinize emin misiniz?"
            onConfirm={() => handleDelete(record.id)}
          >
            <Button type="link" danger>
              Sil
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Button
        type="primary"
        onClick={() => showModal()}
        style={{ marginBottom: 16 }}
      >
        Yeni Buybox Ekle
      </Button>
      <Table
        dataSource={buyboxes}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={{ pageSize: 10 }}
      />

      <Modal
        title={editingBuybox ? "Buybox Düzenle" : "Yeni Buybox Ekle"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="ruleType"
            label="Rule Type"
            rules={[{ required: true, message: "Rule Type gerekli!" }]}
          >
            <Select
              placeholder="Rule Type seçiniz"
              onChange={() => form.resetFields(["ruleData"])}
            >
              <Option value="location">Location</Option>
              <Option value="cycle">Cycle</Option>
              <Option value="direct">Direct</Option>
              <Option value="seller">Seller</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="supplierProductVariantId"
            label="Supplier Product Variant"
            rules={[
              {
                required: true,
                message: "Supplier Product Variant seçilmelidir!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Ürün arayınız"
              onSearch={getSupplierProductVariants}
              onChange={(value) => setSelectedSupplierProductVariantId(value)}
              filterOption={false}
            >
              {supplierProductVariants.map((variant: any) => (
                <Option key={variant.id} value={variant.id}>
                  {variant.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.List name="ruleData">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <RuleDataForm
                    key={field.key}
                    field={field}
                    ruleType={form.getFieldValue("ruleType")}
                    remove={remove}
                  />
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    disabled={!form.getFieldValue("ruleType")}
                  >
                    Rule Data Ekle
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingBuybox ? "Güncelle" : "Ekle"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default BuyboxComponent;
