import React from "react";
import { Button, Form, Input, Select } from "antd";
import adminService from "../../services/adminService";
import alertNotification from "../../utils/alertNotification";

interface Props {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  request: any;
  getRequests: () => Promise<void>;
}
const UpdateResendRequestForm: React.FC<Props> = ({
  request,
  setShowModal,
  getRequests,
}) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    try {
      await adminService.updateResendRequest({ id: request, ...values });
      setShowModal(false);
      alertNotification("success", "Ürün başarıyla güncellendi");
      getRequests();
    } catch (error: any) {
      alertNotification("error", error.response.data.message);
    }
  };

  return (
    <div>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18, offset: 1 }}
        onFinish={handleSubmit}
      >
        <Form.Item label="Onay/Red" name="status">
          <Select>
            <Select.Option value={"approved"}>Onayla</Select.Option>
            <Select.Option value={"rejected"}>Reddet</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Sebebi" name="rejectedReason">
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button style={{ width: "100%" }} type="primary" htmlType="submit">
            Gönder
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateResendRequestForm;
