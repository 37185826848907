import adminService from "src/services/adminService";
import { Modal, Table } from "antd";
import React, { useEffect } from "react";
import { API_REXVEN_URL } from "src/config";
import UpdateResendRequestForm from "src/components/marketplace/UpdateResendRequestForm";

const MArketplaceResend = () => {
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [selectedReq, setSelectedReq] = React.useState<any>(null);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Seller Order ID",
      dataIndex: "sellerOrderId",
      key: "sellerOrderId",
    },
    {
      title: "Sebebi",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Açıklama",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Görsel",
      dataIndex: "file",
      key: "file",
      render: (file: string[]) => {
        return (
          file.length > 0 && (
            <div>
              {file.map((file: string) => (
                <a
                  href={"https://api.rexven.com/" + file}
                  download={true}
                  target="_blank"
                >
                  <img
                    src={"https://api.rexven.com/" + file}
                    alt="resend"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                    }}
                  />
                </a>
              ))}
            </div>
          )
        );
      },
    },
    {
      title: "Durum",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <p>
          {status === "pending"
            ? "Beklemede"
            : status === "approved"
            ? "Onaylandı"
            : "Reddedildi"}
        </p>
      ),
    },
    {
      title: "İşlem",
      dataIndex: "asdas",
      key: "asdas",
      render: (_: any, record: { id: any }) => (
        <button
          onClick={() => {
            setSelectedReq(record.id);
            setOpenUpdateModal(true);
          }}
        >
          İncele
        </button>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
  ];
  const getRequests = async () => {
    try {
      const res = await adminService.getResendRequests(page);
      setData(res.data);
    } catch (error: any) {
      console.log(error.response.data.message);
    }
  };
  useEffect(() => {
    getRequests();
  }, [page]);
  return (
    <div>
      <h1>Yeniden Gönderme Talepleri</h1>
      <Table columns={columns} dataSource={data} pagination={false} />
      <Modal
        width="50%"
        title="Ürün Güncelle"
        footer={null}
        open={openUpdateModal}
        onOk={() => {
          setSelectedReq(null);
          setOpenUpdateModal(false);
        }}
        onCancel={() => {
          setSelectedReq(null);
          setOpenUpdateModal(false);
        }}
      >
        <UpdateResendRequestForm
          setShowModal={setOpenUpdateModal}
          getRequests={getRequests}
          request={selectedReq && selectedReq}
        />
      </Modal>
    </div>
  );
};

export default MArketplaceResend;
